const prices = [
    {
        langIdFrontend: 1, // spanish, latin american
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 2, // spanish, european
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 3, // french
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 4, // german
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 5, // italian
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 6, // japanese
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 7, // mandarin
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 8, // portuguese
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 9, // portuguese
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 10, // arabic
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 11, // russian
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
    {
        langIdFrontend: 12, // latin
        price30Mins20Hr: '9.00',
        price60Mins20Hr: '17.99',
        price30Mins10Hr: '9.25',
        price60Mins10Hr: '18.49',
        price30Mins5Hr: '9.50',
        price60Mins5Hr: '18.99',
        price30Mins2Hr: '9.75',
        price60Mins2Hr: '19.49',
    },
];

module.exports = prices;
